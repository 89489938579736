import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import ProviderInfo from "../../components/ProviderInfo";

import farideh from "../../img/farideh.jpg";

const FaridehZadehMd = () => {
  return (
    <Layout>
      <PageHeader text="Farideh Zadeh, MD" />
      <ProviderInfo header="About Dr. Zadeh" image={farideh} forceExpand>
        <p className="pt-5">
          Farideh A. Zadeh, MD, is a board-certified, highly trained internist
          with more than 25 years of experience serving patients at Sunstate
          Medical Associates in Lake Mary, Florida.
        </p>
        <p className="mt-6">
          Dr. Zadeh attended medical school at Shiraz University of Medical
          Sciences in Shiraz, Iran. She went on to complete her residency in
          internal medicine at Mount Sinai Medical Center - Case Western Reserve
          University in Cleveland, Ohio.
        </p>
        <p className="mt-6">
          Dr. Zadeh is a diplomate of the American Board of Internal Medicine.
          She also received the Preceptor Award from the American Medical
          Association for her efforts as a medical student.
        </p>
        <p className="mt-6">
          Passionate about preventive care, Dr. Zadeh has a special interest in
          women’s health and is known for her warm, caring, and attentive care.
          In addition, she enjoys providing her patients with knowledge about
          how to take care of their bodies and helping them achieve their
          overall health and wellness goals.
        </p>
        <p className="mt-6">
          When she’s not taking care of her patients, she enjoys raising her two
          sons and spending time with family.
        </p>
      </ProviderInfo>
    </Layout>
  );
};

export default FaridehZadehMd;
